import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useIllnessList() {
  // Use toast
  const toast = useToast();

  const refIllnessListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "illnessname",
      label: VueI18n.t("illnessName"),
      sortable: false,
    },
    {
      key: "illnesstypename",
      label: VueI18n.t("illnessTypeName"),
      sortable: false,
    },

    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit"), sortable: false },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const allIllnesses = ref([]);
  const filteredIllnesses = ref([]);
  const allIllnessesTypes = ref([]);
  const filteredIllnessesTypes = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredIllnesses.value.length;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to:
        perPage.value * (currentPage.value - 1) +
          perPage.value * currentPage.value <
        localItemsCount
          ? perPage.value
          : localItemsCount,
      of: allIllnesses.value.length,
    };
  });

  const refetchData = () => {
    if (searchQuery.value)
      filteredIllnesses.value = allIllnesses.value.filter((val) =>
        val.illnessname.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    else filteredIllnesses.value = allIllnesses.value;
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );
  const fetchIllnesses = async (ctx, callback) => {
    var illness = await store
      .dispatch(
        "definitionsModule/fetchIllnesses",
        store.state.app.selectedPlantId
      )
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("illnesses") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allIllnesses.value = illness;
    filteredIllnesses.value = [...illness];

    return illness;
  };

  const fetchIllnessType = async (ctx, callback) => {
    var illnessType = await store
      .dispatch(
        "definitionsModule/fetchIllnessType",
        store.state.app.selectedPlantId
      )
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", {
              value: VueI18n.t("illnessTypes"),
            }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allIllnessesTypes.value = illnessType;
    filteredIllnessesTypes.value = [...illnessType];
    return illnessType;
  };

  const deleteIllness = (ctx) => {
    store
      .dispatch("definitionsModule/deleteIllness", ctx)
      .then((response) => {
        fetchIllnesses();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("illness") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("illness") }),
          },
        });
      });
  };

  const deleteIllnessType = (ctx) => {
    store
      .dispatch("definitionsModule/deleteIllnessType", ctx)
      .then((response) => {
        fetchIllnessType();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("illnessType") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("illnessType") }),
          },
        });
      });
  };
  // *===============================================---*
  // *------------------- UI ---------------------------*
  // *===============================================---*

  return {
    fetchIllnesses,
    fetchIllnessType,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refIllnessListTable,
    deleteIllness,
    deleteIllnessType,
    allIllnesses,
    filteredIllnesses,
    allIllnessesTypes,
    filteredIllnessesTypes,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
