<template>
  <b-modal
    id="modal-exam"
    ref="illnessEditmodalRef"
    no
    centered
    :title="$t('editIllnessType')"
    :ok-title="$t('ok')"
    ok-only
    @ok="editIllnessType(illness)"
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblankvet
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="form"
        class="p-2"
        @submit.prevent="handleSubmit(editIllnessType)"
        @reset.prevent="resetForm"
      >
        <!-- illnesstypename -->
        <validation-provider
          #default="validationContext"
          name="illnesstypename"
          :rules="required"
        >
          <b-form-group
            :label="$t('illnessTypeName')"
            label-for="illnesstypename"
          >
            <b-form-input
              id="illnesstypename"
              v-model="illness.illnesstypename"
              autofocus
              :state="
                formValidation(resetblankillness).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    illness: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data: function () {
    return {
      selectedIllness: { ...this.illness },
      formValidation: formValidation,
      required,
    };
  },
  methods: {
    async resetblankillness() {
      this.selectedIllness = { ...this.illness };
    },
    async editIllnessType(illness) {
      this.$store
        .dispatch("definitionsModule/updateIllnessType", illness)
        .then((val) => {
          if (val != false) {
          }
          this.$refs["illnessEditmodalRef"].hide();

          this.$emit("fetchTypes");
          this.resetblankillness();
        });
    },
    async openModal() {
      this.$refs["illnessEditmodalRef"].show();
    },
  },
};
</script>

<style></style>
