<template>
  <div><illness-type-list /></div>
</template>

<script>
import IllnessTypeList from "./components/IllnessType/IllnessTypeList.vue";
export default {
  components: { IllnessTypeList },
};
</script>

<style></style>
